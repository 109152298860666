.App {
  text-align: center;
}

.Content {
  margin-top: 60px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.addButton {
  position: absolute;
  top: 16px;
  right: 10px;
}

.shareButton {
  position: absolute;
  top: 8px;
  right: 100px;
}

.nosignals {
  color: white;
}

.quickButton {
  padding: 15px;
  margin: 2px;
}

.mapLink {
  /* color: rgb(248, 216, 112); */
  color: rgb(32, 108, 141);
}

.deleteLink {
  color: rgb(200, 97, 97);
}

body {
  background-color: rgb(112, 127, 128)	
}

.signalWindow {
  text-align: left;
  padding: 20px;
  background-color: white;
  margin: 10px;
  /* border: .1em solid rgb(112, 127, 128); */
  border-radius: 8px;
}

.datatable {
  margin-top:85px;
}

.datatable td {
  padding: 10px;
}

.centeredWindow {
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top:85px;
}

.helpWindow {
  margin-left:10px;
  margin-right:10px;
  margin-top:85px;
}

a {
  color:rgb(32, 108, 141);
  text-decoration: none;
  font-size: large;
}

.alignRight {
  text-align: center;
}

.addBtn {
  width: 75px;
}
